/* import __COLOCATED_TEMPLATE__ from './credit-card-payment-iframe.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import config from 'ticketbooth/config/environment';
import type OrderModel from 'ticketbooth/models/order';
import type {
  HppErrorMessage,
  HppSuccessMessage
} from 'ticketbooth/routes/confirm-order';
import { isHppErrorMessage } from 'ticketbooth/routes/confirm-order';
import type CartProviderService from 'ticketbooth/services/cart-provider';
import type GoogleAnalyticsService from 'ticketbooth/services/google-analytics';
import type MessagingService from 'ticketbooth/services/messaging';

interface CreditCardPaymentIframeSignature {
  Args: {
    order?: OrderModel;
    amount?: number;
  };
  Element: HTMLIFrameElement;
}

export default class CreditCardPaymentIframeComponent extends Component<CreditCardPaymentIframeSignature> {
  @service private cartProvider!: CartProviderService;
  @service private messaging!: MessagingService;
  @service private googleAnalytics!: GoogleAnalyticsService;

  @tracked isLoading = true;
  @tracked isError = false;

  @tracked hppUrl: string;
  @tracked outstanding = this.cartProvider.cart.outstanding;
  @tracked retries = 0;

  /**
   * Use `sandbox` attribute to isolate iframe to avoid auto redirect in testing
   * environment.
   *
   * But add `allow-scripts allow-same-origin` to allow local scripts and access
   * origin data in postMessage.
   *
   * In production there is no `sandbox` attribute present, thus no restrictions
   * are applied.
   */
  iframeSandboxAttribute =
    config.environment === 'test'
      ? 'allow-scripts allow-same-origin allow-forms'
      : null;

  /**
   * Permissions Policy
   *
   * https://developer.mozilla.org/en-US/docs/Web/HTTP/Permissions_Policy#iframe_syntax
   *
   * <iframe src="<origin>" allow="<directive> <allowlist>"></iframe>
   */
  iframeAllowAttribute =
    'payment https://hpp.sandbox.realexpayments.com https://hpp.realexpayments.com';

  constructor(owner: unknown, args: CreditCardPaymentIframeSignature['Args']) {
    super(owner, args);

    this.hppUrl = this.compileHppUrl();
    this.cartProvider.onCartChange(this.reloadIframeIfCartChanged);
    this.messaging.onMessage(this.reloadIframeIfPaymentError);
  }

  willDestroy() {
    super.willDestroy();
    this.cartProvider.offCartChange(this.reloadIframeIfCartChanged);
    this.messaging.offMessage(this.reloadIframeIfPaymentError);
  }

  get cartExpiresSoon(): boolean {
    return this.cartProvider.secondsLeft <= 20;
  }

  /**
   * Payments for an existing order will not use the session cart. Instead the
   * API will create a separate refund cart when loading the hpp iframe.
   */
  get isCartPayment(): boolean {
    return !this.args.order;
  }

  get amount(): number {
    return this.args.amount ?? this.args.order?.remaining ?? 0;
  }

  compileHppUrl(): string {
    const { cart } = this.cartProvider;
    const { order } = this.args;
    const { retries } = this;

    if (!order) {
      if (retries > 0) {
        const url = new URL(cart.hppUrl, location.href);
        url.searchParams.set('retries', `${retries}`);
        return url.href;
      }

      return cart.hppUrl;
    }

    const url = new URL(cart.hppUrl, location.href);

    // Creates refund cart for adding payment to existing order
    url.searchParams.set('uuid', order.id);
    url.searchParams.set('installments', 'true');
    url.searchParams.set('amount', String(this.amount));

    return url.href;
  }

  @action
  reloadIframeIfCartChanged() {
    if (!this.isCartPayment) {
      return;
    }

    const { hppUrl, outstanding } = this.cartProvider.cart;

    const cartChanged =
      !this.hppUrl.includes(hppUrl) || this.outstanding !== outstanding;

    if (cartChanged) {
      this.hppUrl = hppUrl;
      this.outstanding = outstanding;
    }
  }

  @action
  reloadIframeIfPaymentError(data?: HppErrorMessage | HppSuccessMessage) {
    if (isHppErrorMessage(data) && data.recaptcha === 'true') {
      this.retries += 1;
      this.hppUrl = this.compileHppUrl();
    }
  }

  @action
  iframeLoaded() {
    this.isLoading = false;
    this.isError = false;

    // Reload specifically after the iframe has loaded to make sure the payment amount is up to date
    this.cartProvider.reload();

    // Report the user has been shown the iframe
    this.googleAnalytics.addPaymentInfo(this.cartProvider.cart);
  }

  @action
  iframeFailed() {
    this.isLoading = false;
    this.isError = true;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    CreditCardPaymentIframe: typeof CreditCardPaymentIframeComponent;
    'credit-card-payment-iframe': typeof CreditCardPaymentIframeComponent;
  }
}
