import type ApplicationInstance from '@ember/application/instance';

import type { CartDomain } from 'ticketbooth/domains/cart';
import type CartProviderService from 'ticketbooth/services/cart-provider';

export function initialize(appInstance: ApplicationInstance): void {
  const cartProvider = appInstance.lookup(
    'service:cart-provider'
  ) as CartProviderService;
  const cartDomain = appInstance.lookup('domain:cart') as CartDomain;
  cartDomain.cartProvider = cartProvider;
}

export default {
  name: 'cart',
  initialize
};
