import Component from '@glimmer/component';

import { inject as service } from '@ember/service';

import link from 'ember-link/helpers/link';
import type MediaService from 'ember-responsive/services/media';

import Icon from 'tangram/components/icon';
import htmlSafeSanitized from 'tangram/helpers/html-safe-sanitized';
import CartProvider from 'ticketbooth/components/cart-provider';
import EventHubHeaderMenu from 'ticketbooth/components/event-hub/header/menu';
import HeaderCart from 'ticketbooth/components/header/cart';
import HeaderItem from 'ticketbooth/components/header/item';
import SkipLinkComponent from 'ticketbooth/components/skip-link';
import translate from 'ticketbooth/helpers/translate';
import branding from 'ticketbooth/modifiers/branding';

export default class EventHubHeader extends Component {
  @service media!: MediaService;

  <template>
    {{! @glint-nocheck: not typesafe yet }}
    <header class="flex flex-col items-center" data-test-header ...attributes>
      <SkipLinkComponent />
      <nav class="flex {{if this.media.isLg "justify-center" "justify-between"}} w-full min-h-14"
        {{branding "backgroundColor" "navbar_bg_color"}}
        {{branding "color" "navbar_fg_color"}}
      >
        <div role="menubar" class="flex flex-wrap justify-between flex-grow max-w-296">
          {{#if this.media.isLg}}
            {{#let (link route="event-hub.index") as |l|}}
              <HeaderItem @onClick={{l.transitionTo}} @isActive={{l.isActive}} >
                <Icon @name="qr-code" @w="w-4" @h="h-full" @classes="mr-2 fill-current opacity-60" />
                {{htmlSafeSanitized (translate "eventhub.header.my_items")}}
              </HeaderItem>
            {{/let}}
            {{#let (link route="event-hub.pre-order") as |l|}}
              <HeaderItem @onClick={{l.transitionTo}} @isActive={{l.isActive}} >
                <Icon @name="basket" @w="w-4" @h="h-full" @classes="mr-2 fill-current opacity-60" />
                {{htmlSafeSanitized (translate "eventhub.header.preorder")}}
              </HeaderItem>
            {{/let}}
            {{#let (link route="event-hub.event-information") as |l|}}
              <HeaderItem @onClick={{l.transitionTo}} @isActive={{l.isActive}} >
                <Icon @name="info" @w="w-4" @h="h-full" @classes="mr-2 fill-current opacity-60" />
                {{htmlSafeSanitized (translate "eventhub.header.event_information")}}
              </HeaderItem>
            {{/let}}
            {{#let (link route="event-hub.map") as |l|}}
              <HeaderItem @onClick={{l.transitionTo}} @isActive={{l.isActive}} >
                <Icon @name="map" @w="w-4" @h="h-full" @classes="mr-2 fill-current opacity-60" />
                {{htmlSafeSanitized (translate "eventhub.header.map")}}
              </HeaderItem>
            {{/let}}
          {{else}}
            <EventHubHeaderMenu />
          {{/if}}

          <div class="flex justify-end flex-grow">
            <CartProvider as |provider|>
              <HeaderCart @cart={{provider.state.cart}} @isLoading={{provider.state.isLoading}} />
            </CartProvider>
          </div>
        </div>
      </nav>
    </header>
  </template>
}
