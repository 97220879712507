/* import __COLOCATED_TEMPLATE__ from './cart-provider.hbs'; */
import Component from '@glimmer/component';

import { NotFoundError } from '@ember-data/adapter/error';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { isBlank } from '@ember/utils';

import type NotificationsService from 'tangram/services/notifications';
import { getAllUserMessagesOrDefault } from 'tangram/utils/errors';
import type BenefitProductModel from 'ticketbooth/models/benefit-product';
import type CartModel from 'ticketbooth/models/cart';
import type { GiftAidPreference } from 'ticketbooth/models/customer';
import type DonationProductModel from 'ticketbooth/models/donation-product';
import type EventModel from 'ticketbooth/models/event';
import type EventTicketPriceModel from 'ticketbooth/models/event-ticket-price';
import type FulfillmentProductModel from 'ticketbooth/models/fulfillment-product';
import type { ProductGroup, TicketGroup } from 'ticketbooth/models/line-item';
import { isTicketGroup } from 'ticketbooth/models/line-item';
import type ProductModel from 'ticketbooth/models/product';
import type { ProductPurchaseOptions } from 'ticketbooth/models/product';
import type ProductLineItemModel from 'ticketbooth/models/product-line-item';
import type RedemptionModel from 'ticketbooth/models/redemption';
import type TicketAllocationModel from 'ticketbooth/models/ticket-allocation';
import type TicketLineItemModel from 'ticketbooth/models/ticket-line-item';
import type CartProviderService from 'ticketbooth/services/cart-provider';
import type ErrorsService from 'ticketbooth/services/errors';
import type GoogleAnalyticsService from 'ticketbooth/services/google-analytics';
import type LocaleService from 'ticketbooth/services/locale';
import type SeatEditorService from 'ticketbooth/services/seat-editor';
import type { Extra } from 'ticketbooth/utils/extras';

import type { DonationsPromptForm } from './donations-prompt-form';

interface CartProviderSignature {
  Args: {
    completedRoute?: string;
    completedRouteQueryParams?: object;
  };
  Blocks: {
    default: [
      {
        state: {
          isLoading: boolean;
          didError: boolean;
          didSucceed: boolean;
          isExpired: boolean;
          isReadyForCheckout: boolean;
          hasOutstanding: boolean;
          hasRedeemedRewards: boolean;
          secondsLeft: number;
          cart: CartModel;
        };
        actions: {
          reload: Function;
          changeGroupQuantity: CartProviderComponent['changeGroupQuantity'];
          changeProductQuantity: CartProviderComponent['changeProductQuantity'];
          changeFulfillmentProduct: CartProviderComponent['changeFulfillmentProduct'];
          changeDonationProduct: CartProviderComponent['changeDonationProduct'];
          changePromotionCode: CartProviderComponent['changePromotionCode'];
          changeExtras: CartProviderComponent['changeExtras'];
          confirmDonationsPrompt: CartProviderComponent['confirmDonationsPrompt'];
          confirmGiftAidPrompt: CartProviderComponent['confirmGiftAidPrompt'];
          addTickets: CartProviderComponent['addTickets'];
          addBenefitProduct: CartProviderComponent['addBenefitProduct'];
          addRewardPayment: CartProviderComponent['addRewardPayment'];
          addVoucherCode: CartProviderComponent['addVoucherCode'];
          repickSeats: CartProviderComponent['repickSeats'];
          removeItemGroup: CartProviderComponent['removeItemGroup'];
          removeVoucherPayment: CartProviderComponent['removeVoucherPayment'];
          emptyCart: CartProviderComponent['emptyCart'];
          confirmCart: CartProviderComponent['confirmCart'];
          confirmUUID: CartProviderComponent['confirmUUID'];
        };
      }
    ];
  };
}

export default class CartProviderComponent extends Component<CartProviderSignature> {
  @service private errors!: ErrorsService;
  @service private notifications!: NotificationsService;
  @service private cartProvider!: CartProviderService;
  @service private router!: RouterService;
  @service private locale!: LocaleService;
  @service private seatEditor!: SeatEditorService;
  @service private googleAnalytics!: GoogleAnalyticsService;

  get isExpired() {
    return this.cartProvider.isExpired;
  }

  get isReadyForCheckout() {
    return this.cartProvider.cart.hasItems && !this.isExpired;
  }

  get hasOutstanding() {
    return this.cartProvider.cart.outstanding > 0;
  }

  get completedRoute() {
    return this.args.completedRoute ?? 'order-completed';
  }

  get completedRouteQueryParams() {
    return this.args.completedRouteQueryParams ?? {};
  }

  @action
  async reloadCart() {
    return this.cartProvider.reload();
  }

  @action
  async repickSeats(lineItemGroup: TicketGroup) {
    await this.seatEditor.repickSeats(lineItemGroup);
  }

  @action
  async removeItemGroup(lineItemGroup: TicketGroup | ProductGroup) {
    try {
      if (isTicketGroup(lineItemGroup)) {
        await this.cartProvider.cart.removeTicketGroup(lineItemGroup);
      } else {
        await this.cartProvider.cart.removeLineItems(lineItemGroup.lineItems);
      }
    } catch (error) {
      this.handleError(
        error,
        `Could not remove ${lineItemGroup.name}... Please try again`
      );
      throw error;
    }
  }

  @action
  async emptyCart() {
    try {
      await this.cartProvider.cart.emptyCart();
    } catch (error) {
      this.handleError(error, 'Could not empty cart. Please try again');
      throw error;
    }
  }

  @action
  async changeFulfillmentProduct(product: FulfillmentProductModel) {
    try {
      await this.cartProvider.cart.changeFulfillmentProduct(product);
    } catch (error) {
      this.handleError(
        error,
        'Could not change delivery method. Please try again'
      );
      throw error;
    }
  }

  @action
  async changeGroupQuantity(
    lineItemGroup: TicketGroup | ProductGroup,
    quantity: number
  ) {
    if (quantity === 0) {
      return this.removeItemGroup(lineItemGroup);
    }

    try {
      await this.cartProvider.cart.changeGroupQuantity(lineItemGroup, quantity);
    } catch (error) {
      this.handleError(error, 'Could not change quantity. Please try again');
      throw error;
    }
  }

  @action
  async changeProductQuantity(
    product: ProductModel | ProductPurchaseOptions,
    quantity: number
  ) {
    try {
      await this.cartProvider.cart.changeProductQuantity(product, quantity);
    } catch (error) {
      this.handleError(error, 'Could not change quantity. Please try again');
      throw error;
    }
  }

  @action
  async addTickets(
    event: EventModel,
    eventTicketPrice: EventTicketPriceModel,
    ticketAllocations: TicketAllocationModel[],
    quantity: number
  ) {
    try {
      await this.cartProvider.cart.addTicketLineItems(
        event,
        eventTicketPrice,
        ticketAllocations,
        quantity
      );
    } catch (error) {
      this.handleError(error, 'Could not add tickets. Please try again');
      throw error;
    }
  }

  @action
  async addBenefitProduct(
    product: BenefitProductModel,
    agreed: boolean,
    giftEmail: string | null
  ) {
    try {
      await this.cartProvider.cart.addBenefitProduct(
        product,
        agreed,
        giftEmail
      );
    } catch (error) {
      this.handleError(
        error,
        'Could not add benefit product. Please try again'
      );
      throw error;
    }
  }

  @action
  async changeDonationProduct(
    product: DonationProductModel,
    price: number,
    agreed: boolean | null
  ) {
    try {
      await this.cartProvider.cart.changeDonationProduct(
        product,
        price,
        agreed
      );
    } catch (error) {
      this.handleError(error, 'Could not add donation. Please try again');
      throw error;
    }
  }

  @action
  async confirmDonationsPrompt(form: DonationsPromptForm) {
    try {
      const operations = form.donations
        .map(donation =>
          this.cartProvider.cart.createChangeDonationProductOp(
            donation.product,
            donation.selectedPrice,
            donation.agreed,
            donation.optOut
          )
        )
        .flat();
      if (operations.length > 0) {
        await this.cartProvider.cart.updateLineItems({ ops: operations });
      }
    } catch (error) {
      this.handleError(error, 'Could not confirm donation. Please try again');
      throw error;
    }
  }

  @action
  async confirmGiftAidPrompt(
    optOut: boolean,
    preference: GiftAidPreference | null
  ) {
    try {
      this.cartProvider.cart.confirmGiftAidPreference({ optOut, preference });
    } catch (error) {
      this.handleError(
        error,
        'Could not confirm gift aid preference. Please try again'
      );
      throw error;
    }
  }

  @action
  async changePromotionCode(promotionCode: string | null) {
    try {
      await this.cartProvider.cart.changePromotionCode(promotionCode);
      const message = isBlank(promotionCode)
        ? 'promotion_code.code_removed'
        : 'promotion_code.code_set';
      this.notifications.success(this.locale.translate(message));
    } catch (error) {
      if (error instanceof NotFoundError) {
        this.notifications.error(
          this.locale.translate('errors.promotion_code.not_found')
        );
      } else {
        this.handleError(
          error,
          this.locale.translate('errors.promotion_code.default')
        );
      }
      throw error;
    }
  }

  @action
  async changeExtras(
    extrasPerLineItems: {
      lineItem: TicketLineItemModel | ProductLineItemModel;
      extras: Extra[] | null;
    }[]
  ) {
    try {
      await this.cartProvider.cart.changeExtras(extrasPerLineItems);
    } catch (error) {
      this.handleError(error, 'Could not change extras. Please try again');
      throw error;
    }
  }

  @action
  async confirmCart() {
    return this.confirmUUID(this.cartProvider.cart.uuid);
  }

  @action
  async confirmUUID(uuid: string, connectedOrder?: string) {
    try {
      const cart = this.cartProvider.cart;
      const order = await cart.confirm(uuid, connectedOrder);
      this.router.transitionTo(this.completedRoute, {
        queryParams: {
          uid: order.id,
          ...this.completedRouteQueryParams
        }
      });
      this.googleAnalytics.purchase(order);
    } catch (error) {
      this.handleError(error, 'Could not confirm the cart. Please try again');
      throw error;
    }
  }

  @action
  async addVoucherCode({ code }: { code: string }) {
    try {
      return await this.cartProvider.cart.addVoucherCode(code);
    } catch (error) {
      if (error instanceof NotFoundError) {
        this.notifications.error(
          this.locale.translate('checkout.voucher_not_found')
        );
      } else {
        this.handleError(
          error,
          'Could not add voucher payment. Please try again'
        );
      }
      throw error;
    }
  }

  @action
  async removeVoucherPayment(redemption: RedemptionModel) {
    try {
      const payment = this.cartProvider.cart.voucherPayments.find(
        payment => payment.redemption === redemption
      )!;
      await this.cartProvider.cart.deleteVoucherPayment(payment);
    } catch (error) {
      if (error instanceof NotFoundError) {
        this.notifications.error('Sorry, voucher not found.');
      } else {
        this.handleError(
          error,
          'Could not remove voucher payment. Please try again'
        );
      }
      throw error;
    }
  }

  @action
  async addRewardPayment() {
    try {
      await this.cartProvider.cart.addRewardPayment();
    } catch (error) {
      this.handleError(
        error,
        'Could not redeem rewards balanace. Please try again'
      );
      throw error;
    }
  }

  private handleError(error: any, defaultMsg: string) {
    const messages = getAllUserMessagesOrDefault(error, { defaultMsg });
    this.errors.log(error);
    this.notifications.error(messages.join('. '));
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    CartProvider: typeof CartProviderComponent;
  }
}
