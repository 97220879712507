import type { ResourceIdentifier } from 'ticketoffice-api';

import { attr, belongsTo } from '@ember-data/model';

import RecommendableModel from 'ticketbooth/models/recommendable';
import type { ExtrasDefinition } from 'ticketbooth/utils/extras';
import { extractExtras } from 'ticketbooth/utils/extras';

import type AssetAttachmentModel from './asset-attachment';
import type EventModel from './event';

export type ProductPurchaseOptions = {
  product: ProductModel;
  event: EventModel | ResourceIdentifier;
};

export function isProductPurchaseOptions(
  product: ProductModel | ProductPurchaseOptions
): product is ProductPurchaseOptions {
  return (product as ProductPurchaseOptions).product !== undefined;
}

export default class ProductModel extends RecommendableModel {
  isDonationProduct = false;

  @attr('string') name!: string;
  @attr('number') price!: number;
  @attr('string') comment!: string;
  @attr('boolean') giftable!: boolean;
  /**
   * See `noremove` tag on products
   */
  @attr('boolean', { defaultValue: true }) canRemoveFromCart!: boolean;
  @attr() extras!: ExtrasDefinition | {} | null;

  @belongsTo('asset-attachment', { async: false, inverse: null })
  defaultAttachment!: AssetAttachmentModel | null;

  get isFutureGiftable() {
    return false;
  }

  get extrasDefinition() {
    return extractExtras(this.extras);
  }

  get hasExtras() {
    return this.extrasDefinition !== null;
  }

  get googleAnalyticsName(): string {
    return this.name.replace(/"/g, ' ').replace(/\n/g, '');
  }

  get googleAnalyticsCategory(): string {
    return 'product';
  }

  get attachment() {
    return (
      this.defaultAttachment ??
      this.store.peekRecord('asset-attachment', 'fallback-photo')
    );
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    product: ProductModel;
  }
}
