import {
  OperationsModule as Operations,
  ProductOperationsModule as ProductOperations,
  type ProductOperationsOpts,
  type CreateOperation,
  type ResourceOperations,
  type ResourceOperation
} from 'ticketbooth/domains/cart/operations';
import BenefitProductModel from 'ticketbooth/models/benefit-product';
import type CartModel from 'ticketbooth/models/cart';
import DonationProductModel from 'ticketbooth/models/donation-product';
import type LineItemModel from 'ticketbooth/models/line-item';
import ProductModel from 'ticketbooth/models/product';
import type ProductLineItemModel from 'ticketbooth/models/product-line-item';

/*
 * LineItemsModule
 * ---
 * A module for adding, removing or updating the current line items. This
 * module focuses on the logic required to create/update/delete line items
 * and to persist the changes.
 *
 * > Note for the minute we are relying on the carts action to persist.
 * This will require more time but will be swapped out.
 */

type ValueOf<T> = T[keyof T];

type ProductLineItemOpts = {
  AddLineItem: {
    Product: ProductOperationsOpts['CreateProductOpts'];
    Donation: ProductLineItemOpts['AddLineItem']['Product'] & {
      price: number;
      agreed: boolean;
    };
    Benefit: ProductLineItemOpts['AddLineItem']['Product'] & {
      agreed: boolean;
      giftEmail: string;
    };
  };
};

export type AddLineItemOpts = ValueOf<ProductLineItemOpts['AddLineItem']>;

export type SomeProduct =
  | ProductModel
  | DonationProductModel
  | BenefitProductModel;

export default class LineItemsModule {
  private queue: ResourceOperations['ops'] = [];

  enqueue(operation: ResourceOperation) {
    this.queue = [...this.queue, operation];
    return operation;
  }

  clearQueue() {
    this.queue = [];
  }

  async execute(cart: CartModel): Promise<any> {
    const result = await cart.updateLineItems({ ops: this.queue });
    this.clearQueue();
    return result;
  }

  duplicateLineItem(lineItem: ProductLineItemModel, opts: AddLineItemOpts) {
    return this.addLineItem(lineItem.product, {
      eventId: lineItem.eventId,
      eventHubContext: lineItem.eventHubContext,
      eventHubContextCategory: lineItem.eventHubContextCategory,
      ...opts
    });
  }

  addLineItem(
    p: BenefitProductModel,
    o: ProductLineItemOpts['AddLineItem']['Benefit']
  ): CreateOperation | undefined;
  addLineItem(
    p: DonationProductModel,
    o: ProductLineItemOpts['AddLineItem']['Donation']
  ): CreateOperation | undefined;
  addLineItem(
    p: ProductModel,
    o: ProductLineItemOpts['AddLineItem']['Product']
  ): CreateOperation | undefined;
  addLineItem(
    product: SomeProduct,
    opts: AddLineItemOpts
  ): CreateOperation | undefined {
    if (product instanceof DonationProductModel) {
      return this.addDonationProductLineItem(
        product,
        opts as ProductLineItemOpts['AddLineItem']['Donation']
      );
    }
    if (product instanceof BenefitProductModel) {
      return this.addBenefitProductLineItem(
        product,
        opts as ProductLineItemOpts['AddLineItem']['Benefit']
      );
    }
    if (product instanceof ProductModel) {
      return this.addProductLineItem(
        product,
        opts as ProductLineItemOpts['AddLineItem']['Product']
      );
    }
  }

  addProductLineItem(
    product: ProductModel,
    opts?: ProductLineItemOpts['AddLineItem']['Product']
  ): CreateOperation {
    const createOperation = ProductOperations.createProductOp(product, opts);
    this.enqueue(createOperation);
    return createOperation;
  }

  addDonationProductLineItem(
    product: DonationProductModel,
    opts: ProductLineItemOpts['AddLineItem']['Donation']
  ) {
    const operation = ProductOperations.createDonationProductOp(product, {
      attributes: {
        price: opts.price,
        agreed: opts.agreed
      }
    });
    this.enqueue(operation);
    return operation;
  }

  addBenefitProductLineItem(
    product: BenefitProductModel,
    opts: ProductLineItemOpts['AddLineItem']['Benefit']
  ) {
    const operation = ProductOperations.createBenefitProductOp(product, {
      attributes: {
        agreed: opts.agreed,
        'gift-email': opts.giftEmail
      }
    });
    this.enqueue(operation);
    return operation;
  }

  removeLineItem(lineItem: LineItemModel) {
    const operation = Operations.genDeleteOperation(lineItem);
    this.enqueue(operation);
    return operation;
  }
}
