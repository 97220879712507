import { identity } from 'tangram/utils/json-api';
import type { CreateOperation } from 'tangram/utils/operations-api';
import { createOperation } from 'tangram/utils/operations-api';
import type BenefitProductModel from 'ticketbooth/models/benefit-product';
import type DonationProductModel from 'ticketbooth/models/donation-product';
import type EventModel from 'ticketbooth/models/event';
import type EventTicketPriceModel from 'ticketbooth/models/event-ticket-price';
import type {
  ProductGroup,
  TicketGroup,
  BookingChargeGroup
} from 'ticketbooth/models/line-item';
import type { ProductPurchaseOptions } from 'ticketbooth/models/product';
import ProductModel, {
  isProductPurchaseOptions
} from 'ticketbooth/models/product';
import type { AllocatedTicketSelection } from 'ticketbooth/models/seat-assignment';
import type TicketAllocationModel from 'ticketbooth/models/ticket-allocation';

export type AnyLineItemGroup = ProductGroup | TicketGroup | BookingChargeGroup;
export type Comparator = ProductGroup & TicketGroup & BookingChargeGroup;

export type Comparable = {
  [key in keyof Comparator]?: Comparator[key];
};

const INCLUDES = {
  FULL_LINE_ITEMS: [
    'ticket-line-items',
    'ticket-line-items.event-ticket-price',
    'ticket-line-items.ticket-allocation',
    'ticket-line-items.event',
    'ticket-line-items.event.venue',
    'ticket-line-items.event.show',
    'recommendations.recommendee.default-attachment',
    'ticket-line-items.event.show.event-category',
    'ticket-line-items.seat-assignment',
    'product-line-items',
    'product-line-items.product',
    'product-line-items.product.default-attachment',
    'booking-charge-line-items'
  ],
  AVAIL_FULFILLMENT_PRODUCTS: ['available-fulfillment-products'],
  PROMPT_DONATION_PRODUCTS: [
    'promptable-donation-products',
    'promptable-donation-products.default-attachment'
  ],
  PRODUCT_SUGGESTIONS: [
    'suggested-products',
    'suggested-products.default-attachment'
  ],
  PERMISSION_OPTIONS: ['permission-options'],
  CUSTOMER: ['customer', 'customer.permissions'],
  PAYMENTS: [
    'voucher-payments',
    'voucher-payments.redemption',
    'voucher-payments.reward-redemption',
    'credit-card-payments'
  ]
};

export const INCLUDE_ALL = [
  ...INCLUDES.FULL_LINE_ITEMS,
  ...INCLUDES.AVAIL_FULFILLMENT_PRODUCTS,
  ...INCLUDES.PROMPT_DONATION_PRODUCTS,
  ...INCLUDES.PRODUCT_SUGGESTIONS,
  ...INCLUDES.PERMISSION_OPTIONS,
  ...INCLUDES.CUSTOMER,
  ...INCLUDES.PAYMENTS
].join(',');

export function createTicketLineItemOperation(
  event: EventModel,
  eventTicketPrice: EventTicketPriceModel,
  ticketAllocations: TicketAllocationModel[],
  hint = ''
): CreateOperation {
  const ticketPriceId = eventTicketPrice.ticketPriceId;
  return createOperation({
    type: 'ticket-line-items',
    attributes: { quantity: 1 },
    relationships: {
      event: { data: identity(event) },
      'ticket-price': { data: { id: ticketPriceId, type: 'ticket-prices' } },
      'ticket-allocations': {
        data: ticketAllocations
          .filter(tA => tA.hasEventTicketPrice(eventTicketPrice))
          .map(ticketAllocation => identity(ticketAllocation))
      }
    },
    meta: { hint }
  });
}

export function createSeatedTicketOperation(
  event: EventModel,
  { seatAssignment, eventTicketPrice }: AllocatedTicketSelection
): CreateOperation {
  const ticketPriceId = eventTicketPrice.ticketPriceId;
  return createOperation({
    type: 'ticket-line-items',
    attributes: { quantity: 1 },
    relationships: {
      event: { data: identity(event) },
      'seat-assignment': { data: identity(seatAssignment) },
      'ticket-price': { data: { id: ticketPriceId, type: 'ticket-prices' } },
      'ticket-allocations': {
        data: seatAssignment.visibleTicketAllocations
          .filter(tA => tA.hasEventTicketPrice(eventTicketPrice))
          .map(ticketAllocation => identity(ticketAllocation))
      }
    }
  });
}

export function createProductLineItemOperation(
  product: ProductModel | ProductPurchaseOptions,
  hint = ''
): CreateOperation {
  product = product instanceof ProductModel ? product : product.product;
  const purchaseEventId = isProductPurchaseOptions(product)
    ? product.event.id
    : null;
  return createOperation({
    type: 'product-line-items',
    attributes: {
      quantity: 1,
      ...(purchaseEventId
        ? { 'event-id': { id: purchaseEventId, type: 'events' } }
        : {})
    },
    relationships: { product: { data: identity(product) } },
    meta: { hint }
  });
}

export function createDonationProductLineItemOperation(
  product: DonationProductModel,
  price: number,
  agreed: boolean
): CreateOperation {
  return createOperation({
    type: 'product-line-items',
    attributes: { quantity: 1, price, agreed },
    relationships: { product: { data: identity(product) } }
  });
}

export function createBenefitProductLineItemOperation(
  product: BenefitProductModel,
  agreed: boolean,
  giftEmail: string | null
): CreateOperation {
  return createOperation({
    type: 'product-line-items',
    attributes: { quantity: 1, agreed, 'gift-email': giftEmail },
    relationships: { product: { data: identity(product) } }
  });
}
