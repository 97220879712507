import Component from '@glimmer/component';

import type { Store } from '@ember-data/store';
import { inject as service } from '@ember/service';

import { useMachine } from 'ember-statecharts';

import Icon from 'tangram/components/icon';
import Loading from 'tangram/components/loading';
import { SimpleLoadRecordsMachine } from 'tangram/lib/machines/simple-load-records';
import ModalContent from 'ticketbooth/components/modal/content';
import translate from 'ticketbooth/helpers/translate';
import type EventModel from 'ticketbooth/models/event';
import type OrderModel from 'ticketbooth/models/order';
import type UserAgentService from 'ticketbooth/services/user-agent';

interface EventHubWalletsModalArgs {
  Args: {
    order: OrderModel;
    event: EventModel;
    close: Function;
  };
}

export default class EventHubWalletsModal extends Component<EventHubWalletsModalArgs> {
  @service store!: Store;
  @service userAgent!: UserAgentService;

  get wallets() {
    return this.statechart.state?.context.records;
  }

  get multipasses() {
    return this.wallets?.firstObject?.multipasses;
  }

  get hasOneMultipass() {
    return this.multipasses?.length === 1;
  }

  get isLoading(): boolean {
    return !!this.statechart.state?.matches('loading');
  }

  get isSucceeded(): boolean {
    return !!this.statechart.state?.matches('success');
  }

  statechart = useMachine(this, () => ({
    machine: SimpleLoadRecordsMachine.withConfig({
      guards: {
        searchOnStartup: () => true
      },
      services: {
        search: () => {
          return this.store.query('wallet', {
            ref: `${this.args.event.id}:${this.args.order.uuid}`,
            include: 'passes,multipasses'
          });
        }
      }
    })
  }));

  <template>
    <ModalContent as |modal|>
      <modal.ui.header @close={{@close}}>
        <h2 data-test-wallet-title>{{translate "eventhub.wallets.qr_downloads"}}</h2>
      </modal.ui.header>
      <modal.ui.body class="md:min-w-120">
        {{#if this.isLoading}}
          <Loading />
        {{else if this.isSucceeded}}
          {{#each this.wallets as |wallet|}}
            {{#if wallet.multipasses}}
              <div class="text-lg leading-none mb-8">
                {{translate "eventhub.wallets.batch_download"}}
              </div>

              {{#each wallet.multipasses as |multipass|}}
                <div class="flex justify-between bg-gray-200 p-4 my-4 rounded items-center" data-test-wallet-multipass>
                  <div class="font-semibold">
                    {{#if this.hasOneMultipass}}
                      {{translate "eventhub.wallets.add_all_to_wallet"}}
                    {{else}}
                      {{translate "eventhub.wallets.add_from_to" from=multipass.fromIndex to=multipass.toIndex}}
                    {{/if}}
                  </div>
                  {{#if this.userAgent.isApple}}
                    <a href={{multipass.appleMultipassUrl}} target="_blank" rel="noopener noreferrer">
                      <Icon @name="add-to-apple-wallet" @w="" />
                    </a>
                  {{else}}
                    <a href={{multipass.googleMultipassUrl}} target="_blank" rel="noopener noreferrer">
                      <Icon @name="add-to-google-wallet" @w="" />
                    </a>
                  {{/if}}
                </div>
              {{/each}}

              <hr class="my-8 -mx-4" />
            {{/if}}

            {{#if wallet.passes}}
              <div class="text-lg leading-none mb-8">
                {{translate "eventhub.wallets.pass_download"}}
              </div>

              {{#each wallet.passes as |pass|}}
                <div class="flex justify-between bg-gray-200 p-4 my-2 rounded items-center" data-test-wallet-pass>
                  <div class="font-semibold">{{pass.walletName}}</div>
                  {{#if this.userAgent.isApple}}
                    <a href={{pass.appleMultipassUrl}} target="_blank" rel="noopener noreferrer">
                      <Icon @name="add-to-apple-wallet" @w="" />
                    </a>
                  {{else}}
                    <a href={{pass.googleMultipassUrl}} target="_blank" rel="noopener noreferrer">
                      <Icon @name="add-to-google-wallet" @w="" />
                    </a>
                  {{/if}}
                </div>
              {{/each}}
            {{/if}}
          {{/each}}
        {{else}}
          <div class="text-red-500">{{translate "errors.default"}}</div>
        {{/if}}
      </modal.ui.body>
    </ModalContent>
  </template>
}
