import type ApplicationInstance from '@ember/application/instance';

import ENV from 'ticketbooth/config/environment';
import type PreloadService from 'ticketbooth/services/preload';

const CUSTOM_COOKIES = ['consent_settings'];

export default {
  name: 'cookie-consent',
  after: 'data-layers',

  initialize(appInstance: ApplicationInstance) {
    // We only load CookieControl if it's not in tests
    if (window['CookieControl'] && ENV.environment === 'production') {
      const config = {
        apiKey: '0de354718dfe978572f6a8ba8fa7162e591d384d',
        product: 'PRO_MULTISITE',
        necessaryCookies: [
          '_ticketsolve_session',
          '_RequestVerificationToken',
          'ASP.NET_SessionId',
          'cookie_notice_website_accepted',
          'session-id',
          'locale',
          ...CUSTOM_COOKIES
        ],
        text: {
          thirdPartyTitle: 'Warning: Some cookies require your attention',
          thirdPartyDescription:
            'Consent for some third party cookies can not be automatically revoked. Please follow the link below if you want to opt out of them.'
        },
        optionalCookies: [
          {
            name: 'analytics',
            label: 'Analytics Cookies',
            description:
              'Analytical cookies help us to improve our website by collecting and reporting information on its usage.',
            cookies: [
              '_ga',
              '_ga*',
              '_gid',
              '_gat',
              '__utma',
              '__utmt',
              '__utmb',
              '__utmc',
              '__utmz',
              '__utmv'
            ],
            onAccept() {
              window['TS_ANALYTICS_consent_opt_in'] = true;
              const dataLayers = appInstance.lookup('service:data-layers');
              dataLayers.event('ts.consent_update.analytics');
              if (window['FS']) {
                window['FS'].consent(true);
              }
            },
            onRevoke() {
              window['TS_ANALYTICS_consent_opt_in'] = false;
              const dataLayers = appInstance.lookup('service:data-layers');
              dataLayers.event('ts.consent_update.analytics');
              if (window['FS']) {
                window['FS'].consent(false);
              }
            }
          },
          {
            name: 'marketing',
            label: 'Marketing Cookies',
            description:
              'We use marketing cookies to help us improve the relevancy of advertising campaigns you receive.',
            cookies: ['trackers', 'fs_uid'],
            onAccept() {
              window['TS_MARKETING_consent_opt_in'] = true;
              const dataLayers = appInstance.lookup('service:data-layers');
              dataLayers.event('ts.consent_update.marketing');
            },
            onRevoke() {
              window['TS_MARKETING_consent_opt_in'] = false;
              const dataLayers = appInstance.lookup('service:data-layers');
              dataLayers.event('ts.consent_update.marketing');
            }
          }
        ],

        position: 'LEFT',
        theme: 'LIGHT',
        rejectButton: true,
        branding: {
          fontColor: '#333',
          backgroundColor: '#f4f4f4',
          fontSizeTitle: '1.2em',
          fontSizeIntro: '1em',
          fontSizeHeaders: '1em',
          fontSize: '1em',
          toggleText: '#fff',
          toggleColor: '#222',
          toggleBackground: '#444',
          buttonIcon: null,
          buttonIconWidth: '64px',
          buttonIconHeight: '64px',
          removeIcon: false,
          removeAbout: false,
          acceptBackground: '#222',
          acceptText: '#f4f4f4'
        },
        excludedCountries: ['all'],
        removeAbout: true
      };

      // We only load CookieControl if there is no custom GTM or
      // if the custom GTM is in the last version with cookies consent
      const preload = appInstance.lookup('service:preload') as PreloadService;
      if (!preload.getValue('gtm_container_id')) {
        window['CookieControl'].load(config);
      } else {
        // wait for the new configuration in the customer GTM container
        setTimeout(() => {
          if (window['ts-show-cookie-consent']) {
            window['CookieControl'].load(config);
          }
        }, 5000);
      }
    }
  }
};
