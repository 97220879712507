/* import __COLOCATED_TEMPLATE__ from './select-benefit.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import sortBy from 'lodash-es/sortBy';

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isBlank } from '@ember/utils';

import type { DropdownOption } from 'tangram/components/dropdown';
import type BenefitProductModel from 'ticketbooth/models/benefit-product';
import type ProductLineItemModel from 'ticketbooth/models/product-line-item';
import type CartProviderService from 'ticketbooth/services/cart-provider';
import type TicketboothErrorsService from 'ticketbooth/services/errors';
import type MembershipService from 'ticketbooth/services/membership';
import type PreloadService from 'ticketbooth/services/preload';

import type CartProviderComponent from './cart-provider';

interface SelectBenefitSignature {
  Args: {
    product: BenefitProductModel;
    onSelect: CartProviderComponent['addBenefitProduct'];
  };
}

type BenefitOption = null | 'add_as_gift' | 'add_to_cart';

export default class SelectBenefitComponent extends Component<SelectBenefitSignature> {
  @service private membership!: MembershipService;
  @service private cartProvider!: CartProviderService;
  @service private preload!: PreloadService;
  @service private errors!: TicketboothErrorsService;

  @tracked isEditingGiftEmail: boolean = false;
  @tracked agreed: boolean = false;

  /**
   * 1. List benefits added to cart as (readonly) options
   *
   * Options:
   *   [email1@example.com]
   *   [email2@example.com]
   *   [Member Name]
   */
  private get benefitsInCart(): ProductLineItemModel[] {
    return this.cartProvider.cart.lineItemsForProduct(this.args.product);
  }
  private get benefitsInCartOptions(): DropdownOption<null>[] {
    const memberName = this.membership.member?.name;

    return sortBy(this.benefitsInCart, lineItem => lineItem.giftEmail).map(
      lineItem => ({
        label: lineItem.isBenefitGift ? lineItem.giftEmail! : memberName!,
        value: null
      })
    );
  }

  /**
   * 2. Add/Renew benefit to current user
   *
   * Option: [Add to cart] / [Renew benefit]
   */
  private get canAddUserBenefit(): boolean {
    const isAlreadyInCart = this.benefitsInCart.some(
      lineItem => lineItem.isBenefitForUser
    );
    return this.membership.isEnabled && !isAlreadyInCart;
  }
  get userBenefitOption(): DropdownOption<BenefitOption> {
    const hasAlreadyActiveBenefit =
      this.membership.member?.activeBenefit?.benefitProduct ===
      this.args.product;
    return {
      label: hasAlreadyActiveBenefit ? 'Renew benefit' : 'Add to cart',
      value: 'add_to_cart'
    };
  }

  /**
   * 3. Gift Benefits
   *
   * Option: [Add as gift]
   */
  private get canGiftBenefits(): boolean {
    return this.preload.getValue('display_add_as_gift');
  }
  get giftOption(): DropdownOption<BenefitOption> {
    return { label: 'Add as gift', value: 'add_as_gift' };
  }

  get options(): DropdownOption<BenefitOption | null>[] {
    const readonlyOptions = this.benefitsInCartOptions;
    const selectableOptions: DropdownOption<BenefitOption>[] = [];

    if (this.canAddUserBenefit) {
      selectableOptions.push(this.userBenefitOption);
    }

    if (this.canGiftBenefits) {
      selectableOptions.push(this.giftOption);
    }

    if (readonlyOptions.length > 0) {
      return [
        ...readonlyOptions,
        { label: '', value: null, divider: true },
        ...selectableOptions
      ];
    }

    return selectableOptions;
  }

  get isOnlyGiftOption() {
    return this.canGiftBenefits && !this.canAddUserBenefit;
  }

  get isOnlyAddUserBenefitOption() {
    return !this.canGiftBenefits && this.canAddUserBenefit;
  }

  get selectPlaceholder() {
    const { benefitsInCart } = this;
    if (benefitsInCart.length) {
      return `${benefitsInCart.length} in cart`;
    }
    return 'Select';
  }

  @action
  selectByNativeSelect(event: Event & { target: HTMLSelectElement }) {
    this.select(event.target.value as BenefitOption, null);
    // Reset selection to the placeholder `selectPlaceholder`
    event.target.selectedIndex = 0;
  }

  @action
  selectByDropdown(option: BenefitOption) {
    return this.select(option, null);
  }

  @action
  async addAsGift(email: string) {
    await this.select('add_as_gift', email);
  }

  @action
  async addUserBenefit() {
    await this.select('add_to_cart', null);
  }

  @action
  private async select(option: BenefitOption, email: string | null) {
    if (isBlank(option)) {
      return;
    }

    const hasGiftEmail = email !== null;

    if (option === 'add_as_gift' && !hasGiftEmail) {
      this.displayGiftEmailInput();
      return;
    }

    try {
      await this.args.onSelect(this.args.product, this.agreed, email ?? null);

      if (hasGiftEmail) {
        this.hideGiftEmailInput();
      }
    } catch (error) {
      this.errors.log(error);
      throw error;
    }
  }

  @action
  displayGiftEmailInput() {
    this.isEditingGiftEmail = true;
  }

  @action
  hideGiftEmailInput() {
    this.isEditingGiftEmail = false;
  }

  @action
  changeAgreed() {
    this.agreed = !this.agreed;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    SelectBenefit: typeof SelectBenefitComponent;
    'select-benefit': typeof SelectBenefitComponent;
  }
}
