import type RouterService from 'node_modules/@types/ember__routing/router-service';

import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import type NotificationsService from 'tangram/services/notifications';
import type PreloadService from 'ticketbooth/services/preload';

export default class ApplicationController extends Controller {
  @service private preload!: PreloadService;
  @service private notifications!: NotificationsService;
  @service private router!: RouterService;

  get isEventHub() {
    return this.router.currentRouteName.includes('event-hub');
  }

  @action
  displayFlashMessage(): void {
    const error = this.preload.getValue('flash.error');
    if (error) {
      this.notifications.error(error);
    }
  }
}
