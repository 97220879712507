import Component from '@glimmer/component';

import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

import link from 'ember-link/helpers/link';

import Icon from 'tangram/components/icon';
import htmlSafeSanitized from 'tangram/helpers/html-safe-sanitized';
import type NotificationsService from 'tangram/services/notifications';
import type ShareService from 'tangram/services/share';
import HeaderMenuTrigger from 'ticketbooth/components/header/menu/trigger';
import translate from 'ticketbooth/helpers/translate';

interface Signature {
  Args: {};
}

export default class EventHubHeaderMenu extends Component<Signature> {
  @service private share!: ShareService;
  @service private notifications!: NotificationsService;
  @service private router!: RouterService;

  @action shareEventHubLink() {
    const currentURL = this.router.currentURL;

    return this.share.share({
      title: 'EventHub',
      url: currentURL,
      onSuccess: () => {
        this.notifications.success('Successfully shared the EventHub-URL');
      },
      onError: () => {
        this.notifications.error('Error sharing the EventHub-URL');
      }
    });
  }

  <template>
    <HeaderMenuTrigger>
      <:default as |menu|>
        {{#let (link route='event-hub.index') as |l|}}
          <menu.ui.menuItem
            class='flex'
            @onClick={{l.transitionTo}}
            @isActive={{l.isActive}}
          >
            <Icon
              @name='qr-code'
              @w='w-5'
              @h='h-full'
              @classes='ml-1 fill-current my-auto'
            />
            <span class='inline-flex mt-px ml-4'>
              {{htmlSafeSanitized (translate 'eventhub.header.my_items')}}
            </span>
          </menu.ui.menuItem>
        {{/let}}
        {{#let (link route='event-hub.pre-order') as |l|}}
          <menu.ui.menuItem
            class='flex'
            @onClick={{l.transitionTo}}
            @isActive={{l.isActive}}
          >
            <Icon
              @name='basket'
              @w='w-5'
              @h='h-full'
              @classes='ml-1 fill-current my-auto'
            />
            <span class='inline-flex mt-px ml-4'>
              {{htmlSafeSanitized (translate 'eventhub.header.preorder')}}
            </span>
          </menu.ui.menuItem>
        {{/let}}
        {{#let (link route='event-hub.event-information') as |l|}}
          <menu.ui.menuItem
            class='flex'
            @onClick={{l.transitionTo}}
            @isActive={{l.isActive}}
          >
            <Icon
              @name='info'
              @w='w-5'
              @h='h-full'
              @classes='ml-1 fill-current my-auto'
            />
            <span class='inline-flex mt-px ml-4'>
              {{htmlSafeSanitized (translate 'eventhub.header.event_information')}}
            </span>
          </menu.ui.menuItem>
        {{/let}}
        {{#let (link route='event-hub.map') as |l|}}
          <menu.ui.menuItem
            class='flex'
            @onClick={{l.transitionTo}}
            @isActive={{l.isActive}}
          >
            <Icon
              @name='map'
              @w='w-5'
              @h='h-full'
              @classes='ml-1 fill-current my-auto'
            />
            <span class='inline-flex mt-px ml-4'>
              {{htmlSafeSanitized (translate 'eventhub.header.map')}}
            </span>
          </menu.ui.menuItem>
        {{/let}}
      </:default>
      <:end as |menu|>
        <div>
          <menu.ui.menuItem @onClick={{this.shareEventHubLink}} class='flex'>
            <Icon
              @name='share'
              @w='w-5'
              @h='h-full'
              @classes='ml-1 fill-current my-auto'
            />
            <span class='inline-flex mt-px ml-4'>
              {{htmlSafeSanitized (translate 'share_title')}}
            </span>
          </menu.ui.menuItem>
        </div>
      </:end>
    </HeaderMenuTrigger>
  </template>
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'EventHub::Header::Menu': typeof EventHubHeaderMenu;
    'event-hub/header/menu': typeof EventHubHeaderMenu;
  }
}
