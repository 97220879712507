import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { fn, concat, hash } from '@ember/helper';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import includes from 'ember-composable-helpers/helpers/includes';
// @ts-ignore
import media from 'ember-responsive/helpers/media';
import { eq, gt } from 'ember-truth-helpers';

import Button from 'tangram/components/button';
import isDark from 'tangram/helpers/is-dark';
import type CurrencyService from 'tangram/services/currency';
import CartProvider from 'ticketbooth/components/cart-provider';
import CoverProduct from 'ticketbooth/components/cover/product';
import QuantitySelect from 'ticketbooth/components/quantity-select';
import type { CartDomain } from 'ticketbooth/domains/cart';
import lookAndFeel from 'ticketbooth/helpers/look-and-feel';
import productQuantity from 'ticketbooth/helpers/product-quantity';
import type ContextModel from 'ticketbooth/models/context';
import type ContextCategoryModel from 'ticketbooth/models/context-category';
import type EventModel from 'ticketbooth/models/event';
import type ProductLineItemModel from 'ticketbooth/models/product-line-item';
import branding from 'ticketbooth/modifiers/branding';
import type CartProviderService from 'ticketbooth/services/cart-provider';
import { inject as domain } from 'ticketbooth/utils/domains';

interface EventHubContextsArgs {
  Args: {
    contexts: ContextModel[];
    event: EventModel;
  };
}

export default class EventHubContexts extends Component<EventHubContextsArgs> {
  @domain('cart', this) CartDomain!: CartDomain;

  @service currency!: CurrencyService;
  @service cartProvider!: CartProviderService;

  @tracked selectedContext!: ContextModel;
  @tracked selectedCategories: ContextCategoryModel[] = [];

  constructor(owner: EventHubContexts, args: EventHubContextsArgs['Args']) {
    super(owner, args);
    this.selectedContext = this.args.contexts[0];
  }

  get cart() {
    return this.cartProvider.cart;
  }

  get productLineItems(): ProductLineItemModel[] {
    return this.cart.productLineItems.slice();
  }

  getProductsInCartForCategory({ products }: ContextCategoryModel): number {
    return this.productLineItems.reduce(
      (acc, pli) => (products.includes(pli.product) ? acc + 1 : acc),
      0
    );
  }

  get allCategories() {
    return this.selectedContext?.contextCategories.map(category => ({
      category,
      productsInCart: this.CartDomain.getQuantityInCart<ProductLineItemModel>({
        eventHubContext: this.selectedContext.id,
        eventHubContextCategory: category.id
      })
    }));
  }

  @action
  toggleContext(context: ContextModel) {
    if (this.selectedContext === context) return;
    this.selectedContext = context;
  }

  @action
  toggleCategory(categoryId: ContextCategoryModel) {
    this.selectedCategories = this.selectedCategories?.includes(categoryId)
      ? this.selectedCategories.reject(id => id === categoryId)
      : [...this.selectedCategories, categoryId];
  }

  <template>
    {{! @glint-nocheck: not typesafe yet }}
    <div>
      <div class="p-1 mb-4 rounded flex gap-1"
        {{branding "color" "card_fg_color"}}
        {{branding "backgroundColor" "card_bg_color"}}
        data-test-pre-order-contexts
      >
        {{#each @contexts as |context|}}
          {{#let (eq this.selectedContext context) as |isSelected|}}
            <Button class="rounded p-2 text-xs w-full"
              @onClick={{fn this.toggleContext context}}
              @testSelector="context"
              data-test-context-button="{{context.name}}"
              {{branding "color" "card_fg_color"}}
              {{branding "backgroundColor" "card_bg_color"
                luminance=(if isSelected (if (isDark (lookAndFeel "card_bg_color")) 33 -33))
              }}
              as |button|>
              <button.ui.content>{{context.name}}</button.ui.content>
            </Button>
          {{/let}}
        {{/each}}
      </div>

      <div class="flex flex-col">
        {{#each this.allCategories as |c|}}
          {{#let c.category as |category|}}
            {{#if (gt category.products.length 0)}}
              {{#let (includes category.id this.selectedCategories) as |isSelected|}}

                <Button class="mb-4 p-3 rounded text-left"
                  @onClick={{fn this.toggleCategory category.id}}
                  @testSelector="context-category"
                  data-test-context-category-button="{{category.name}}"
                  {{branding "color" "card_fg_color"}}
                  {{branding "backgroundColor" "card_bg_color"
                    luminance=(if (isDark (lookAndFeel "card_bg_color")) 33 -33)
                  }}
                  as |button|>
                  <button.ui.content class="flex">
                    <div>{{category.name}}</div>
                    {{#if c.productsInCart}}
                      <div class="flex h-4 justify-center ml-1.5 my-auto rounded-full text-xs w-4"
                        {{branding "color" "card_fg_color"}}
                        {{branding "backgroundColor" "card_bg_color"}}
                      >
                        {{c.productsInCart}}
                      </div>
                    {{/if}}
                  </button.ui.content>
                  <button.ui.rightIcon
                    @name={{if isSelected "chevron-down" "chevron-right"}}
                    {{branding "color" "card_fg_color"}}
                  />
                </Button>

                {{#if isSelected}}
                  <div class="flex flex-col">
                    {{#each category.products as |product|}}
                      <div class="flex min-h-28 mx-3 mb-5" data-test-context-category-product>
                        <div class="relative mr-3">
                          <CoverProduct @attachment={{product.attachment}}
                            @wrapperClasses="h-full"
                            class="h-full object-cover"
                          />
                        </div>

                        <div class="flex flex-col flex-grow justify-between overflow-hidden">
                          <div class="flex flex-col">
                            <div class="text-lg flex justify-between font-semibold">
                              <span class="truncate">{{product.name}}</span>
                              <span>{{concat this.currency.symbol product.price}}</span>
                            </div>
                            <div class="text-sm truncate overflow-hidden">{{product.comment}}</div>
                          </div>

                          <div class="flex justify-end">
                            {{#let (hash
                              product=product
                              eventHubContext=this.selectedContext.id
                              eventHubContextCategory=category.id)
                              as |productArgs|}}
                              <QuantitySelect @h="h-10"
                                @filterArgs={{productArgs}}
                                @changeArgs={{productArgs}}
                                @dropdownWidth={{if (media "isTouch") "w-full" "w-18"}}
                                @inputWidth={{if (media "isTouch") "w-full" "w-20"}}
                              />
                            {{/let}}
                          </div>

                        </div>
                      </div>

                    {{/each}}
                  </div>
                {{/if}}

              {{/let}}
            {{/if}}
          {{/let}}
        {{/each}}
      </div>
    </div>
  </template>
}
