import { attr } from '@ember-data/model';

import ProductModel from './product';

export default class BenefitProductModel extends ProductModel {
  @attr('string') declaration!: string;

  get googleAnalyticsVariant(): string {
    return 'product/benefit';
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'benefit-product': BenefitProductModel;
  }
}
