/* import __COLOCATED_TEMPLATE__ from './day-selection.hbs'; */
import Component from '@glimmer/component';
import formatISO from 'date-fns/formatISO';
import groupBy from 'lodash-es/groupBy';

import type EventModel from 'ticketbooth/models/event';

interface ShowEventsListDaySelectionSignature {
  Args: {
    events: EventModel[];
    someEventHasLowAvailability?: boolean;
  };
  Blocks: {
    afterTimeSelection: [unknown];
  };
  Element: HTMLElement;
}

export function groupEventDayKey(event: EventModel) {
  return formatISO(event.dateTime, { representation: 'date' });
}

export default class ShowEventsListDaySelectionComponent extends Component<ShowEventsListDaySelectionSignature> {
  get eventsByDay() {
    return groupBy(this.args.events, event => groupEventDayKey(event));
  }

  get soldoutMessage() {
    return this.args.events[0].soldoutMessage;
  }

  get lastDay(): string {
    const keys = Object.keys(this.eventsByDay);
    return keys[keys.length - 1];
  }

  get allEventsSoldout(): boolean {
    return this.args.events.every(event => event.soldout);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Show::EventsList::DaySelection': typeof ShowEventsListDaySelectionComponent;
    'show/events-list/day-selection': typeof ShowEventsListDaySelectionComponent;
  }
}
