/* import __COLOCATED_TEMPLATE__ from './event-calendar.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { startOfMonth } from 'date-fns';
import addMonths from 'date-fns/addMonths';
import isAfter from 'date-fns/isAfter';
import isSameDay from 'date-fns/isSameDay';
import isToday from 'date-fns/isToday';
import orderBy from 'lodash-es/orderBy';

import type { Store } from '@ember-data/store';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import type EventModel from 'ticketbooth/models/event';
import type ShowModel from 'ticketbooth/models/show';
import type TicketboothErrorsService from 'ticketbooth/services/errors';
import type EventCalendarService from 'ticketbooth/services/event-calendar';
import { findNextBookableEvent } from 'ticketbooth/utils/event';

interface EventCalendarSignature {
  Args: {
    selectedEvent: EventModel;
    onChangeEvent?: (event: EventModel) => Promise<any>;
  };
  Blocks: {
    default: [unknown];
  };
}

/**
 * Manages the calendar date
 */
export default class EventCalendarComponent extends Component<EventCalendarSignature> {
  @service private store!: Store;
  @service private eventCalendar!: EventCalendarService;
  @service private errors!: TicketboothErrorsService;

  @tracked private noEventsMonthDate: Date | null = null;

  /**
   * The calendar date is usually determined by the selected event.
   *
   * Only if there is a month without events, we fallback to `noEventsMonthDate`,
   * because we are not able to change to an event in this month.
   */
  get selectedDate(): Date {
    return this.noEventsMonthDate ?? this.args.selectedEvent.dateTime;
  }

  get show(): ShowModel {
    return this.args.selectedEvent.show;
  }

  get todayEvents(): EventModel[] {
    const { show } = this;
    return this.store
      .peekAll('event')
      .filter(event => event.show === show && isToday(event.dateTime));
  }

  get nextBookableEvents(): EventModel[] {
    const { selectedDate, show } = this;
    return this.store
      .peekAll('event')
      .filter(
        event =>
          event.show === show &&
          event.isBookable &&
          isAfter(event.dateTime, selectedDate)
      );
  }

  get hasNextBookableEvent(): boolean {
    return this.nextBookableEvents.length > 0;
  }

  get isTodaySoldout() {
    if (!isToday(this.selectedDate)) {
      return false;
    }

    const { todayEvents } = this;
    if (todayEvents.length === 0) {
      return false;
    }

    return todayEvents.every(({ soldout }) => soldout);
  }

  /**
   * `EventCalendar` only supports month view currently, so any date change is
   * changing the month.
   */
  @action
  async changeMonth(date: Date) {
    const events = await this.eventCalendar.loadEventsOfMonth(this.show, date);
    const monthHasNoEvents = events.length === 0;

    if (monthHasNoEvents) {
      this.noEventsMonthDate = date;
    } else {
      this.noEventsMonthDate = null;
      this.args.onChangeEvent?.(findNextBookableEvent(events)!);
    }
  }

  /**
   * Changing the day will only update the URL and trigger to reload the
   * `EventsList`
   */
  @action
  async changeDay(day: Date) {
    const { show } = this;
    const eventsOfTheDay = this.store
      .peekAll('event')
      .filter(event => event.show === show && isSameDay(event.dateTime, day));
    const event = findNextBookableEvent(eventsOfTheDay);

    if (event) {
      this.args.onChangeEvent?.(event);
    } else {
      this.errors.log(
        new Error(
          `Could not change day to ${day} (event = ${this.args.selectedEvent}`
        )
      );
    }
  }

  @action
  async changeToNextEvent() {
    const { nextBookableEvents } = this;
    const event = orderBy(nextBookableEvents, ({ dateTime }) => dateTime, [
      'asc'
    ])[0];

    if (event) {
      this.args.onChangeEvent?.(event);
    } else {
      this.errors.log(
        new Error(
          `Could not change to next event (event = ${this.args.selectedEvent}`
        )
      );
    }
  }

  @action
  preloadNextMonth() {
    const nextMonth = addMonths(startOfMonth(this.selectedDate), 1);
    this.eventCalendar.loadEventsOfMonth(this.show, nextMonth);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    EventCalendar: typeof EventCalendarComponent;
    'event-calendar': typeof EventCalendarComponent;
  }
}
