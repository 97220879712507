import { attr } from '@ember-data/model';
import { isBlank } from '@ember/utils';

import ProductModel from './product';

export default class DonationProductModel extends ProductModel {
  isDonationProduct: boolean = true;

  @attr('boolean') allowPriceOverride!: boolean;
  @attr('string') declaration!: string;
  @attr() donationAmounts!: number[];

  get sortedDonationAmounts() {
    return this.donationAmounts.sort((a, b) => a - b);
  }

  get googleAnalyticsVariant(): string {
    return 'product/donation';
  }

  get isGiftAidDonation() {
    return !isBlank(this.declaration);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'donation-product': DonationProductModel;
  }
}
