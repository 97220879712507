import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

import type CurrencyService from 'tangram/services/currency';
import type OrderModel from 'ticketbooth/models/order';
import type ProductLineItemModel from 'ticketbooth/models/product-line-item';
import type TicketLineItemModel from 'ticketbooth/models/ticket-line-item';
import type SettingsService from 'ticketbooth/services/settings';

import type { EventHubRouteModel } from '../../routes/event-hub';

export default class EventHubController extends Controller {
  @service currency!: CurrencyService;
  @service settings!: SettingsService;

  declare model: EventHubRouteModel;

  get preorderEnabled(): boolean {
    // return this.settings.getSetting('preorderEnabled');
    return true;
  }

  get order() {
    return this.model.order;
  }

  get ticketLineItems(): TicketLineItemModel[] {
    return this.order.effectiveTicketLineItems.slice();
  }

  get productLineItems(): ProductLineItemModel[] {
    return this.order.connectedOrders.reduce(
      (acc: ProductLineItemModel[], order: OrderModel) => [
        ...acc,
        ...order.effectiveProductLineItems.slice()
      ],
      this.order.effectiveProductLineItems.slice()
    );
  }
}
