import type { SingleResourceDocument } from 'ticketoffice-api';

import JSONAPIAdapter from '@ember-data/adapter/json-api';
import type Store from '@ember-data/store';
import { waitForPromise } from '@ember/test-waiters';
// eslint-disable-next-line
import type { ModelRegistry } from 'ember-data/model';
import type SerializerRegistry from 'ember-data/types/registries/serializer';

import config from 'ticketbooth/config/environment';

export default class ApplicationAdapter extends JSONAPIAdapter {
  get namespace(): string {
    return config.apiNamespace;
  }

  get headers() {
    const csrfToken = document
      .querySelector('meta[name=csrf-token]')
      ?.getAttribute('content');
    if (csrfToken) {
      return { 'X-CSRF-Token': csrfToken };
    }
    return {};
  }

  async fetch<TResponse = SingleResourceDocument>(
    method: 'POST' | 'PATCH' | 'DELETE',
    url: string,
    data?: {}
  ): Promise<TResponse> {
    const promise = this.ajax(url, method, { data });

    await waitForPromise(promise);

    return promise;
  }

  async serializeAndPush<T extends keyof ModelRegistry>(
    store: Store,
    modelName: T,
    response: SingleResourceDocument
  ): Promise<ModelRegistry[T]> {
    const model = store.modelFor(modelName);
    const serializer = store.serializerFor(
      modelName as keyof SerializerRegistry
    );
    const id = response?.data?.id ?? '';

    return store.push(
      serializer.normalizeSingleResponse(
        store,
        model,
        response,
        id,
        'findRecord'
      )
    );
  }
}
