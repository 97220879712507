/* import __COLOCATED_TEMPLATE__ from './quantity-select.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import type { DropdownOption } from 'tangram/components/dropdown';
import { mapOptions } from 'tangram/helpers/map-options';
import type { CartDomain } from 'ticketbooth/domains/cart';
import type ProductModel from 'ticketbooth/models/product';
import type ErrorsService from 'ticketbooth/services/errors';
import { inject as domain } from 'ticketbooth/utils/domains';

interface QuantitySelectSignature {
  Args: {
    quantity: number;
    filterArgs?: {
      product: ProductModel;
      eventId?: string;
      eventHubContext?: string;
      eventHubContextCategory?: string;
    };
    changeArgs: {
      product: ProductModel;
      eventId?: string;
      eventHubContext?: string;
      eventHubContextCategory?: string;
    };
    onChange: (quantity: number) => Promise<void>;
    // Related to ticket line items event and ticket price
    maxTickets?: number;
    minTickets?: number;
    groupSize?: number;
    dropdownWidth?: string;
    inputWidth?: string;
    testSelector?: string;
  };
}

const MAX_RANGE = 9;

export default class QuantitySelectComponent extends Component<QuantitySelectSignature> {
  @domain('cart', this) CartDomain!: CartDomain;

  @service errors!: ErrorsService;

  get filterArgs() {
    return this.args.filterArgs ?? {};
  }

  get lineItems() {
    return this.CartDomain.filterLineItems(this.filterArgs);
  }

  get quantity() {
    return (
      this.args.quantity ?? this.CartDomain.getQuantityInCart(this.filterArgs)
    );
  }

  get changeQuantity() {
    return (quantity: number) =>
      this.CartDomain.changeProductQuantity(
        this.lineItems,
        quantity,
        this.args.changeArgs
      );
  }

  get onChange() {
    return this.args.onChange ?? this.changeQuantity;
  }

  get dropdownWidth(): string {
    return this.args.dropdownWidth ?? 'w-16';
  }
  get inputWidth(): string {
    return this.args.inputWidth ?? 'w-20';
  }

  get minQuantity(): number {
    return this.args.minTickets || 1;
  }
  get maxQuantity(): number {
    if (!this.args.maxTickets) {
      return Infinity;
    }
    return Math.floor(this.args.maxTickets / (this.args.groupSize ?? 1));
  }

  get optionsMin(): number {
    return this.minQuantity;
  }
  get optionsMax(): number {
    return Math.min(this.maxQuantity, this.optionsMin + MAX_RANGE);
  }

  get options(): DropdownOption[] {
    const { optionsMin, optionsMax } = this;

    const length = optionsMax - optionsMin + 1;

    const options = mapOptions([
      // Always allow to set 0, no matter the minimum
      [0, ...Array.from({ length }, (_, i) => i + optionsMin)]
    ]);

    const exceedsRange = length >= MAX_RANGE;
    if (exceedsRange) {
      options.pop();
      options.push({
        value: 'input',
        label: `${optionsMax}+`
      });
    }

    return options;
  }

  get isQuantityOutOfOptionsRange() {
    return !this.options.some(({ value }) => value === this.quantity);
  }

  @tracked isEditingQuantityByInput: boolean = false;
  @tracked isBusy: boolean = false;

  get displayInput() {
    return this.isEditingQuantityByInput || this.isQuantityOutOfOptionsRange;
  }

  @action
  selectQuantity(quantity: number | string | 'input') {
    if (typeof quantity === 'number') {
      this.onSelect(quantity);
    } else if (quantity === 'input') {
      this.displayQuantityInput();
    } else if (typeof quantity === 'string') {
      this.onSelect(parseInt(quantity));
    }
  }

  @action
  selectQuantityByNativeSelect(event: Event & { target: HTMLSelectElement }) {
    this.selectQuantity(
      event.target.value === 'input'
        ? 'input'
        : parseInt(event.target.value, 10)
    );
  }

  @action
  displayQuantityInput() {
    this.isEditingQuantityByInput = true;
  }
  @action
  hideQuantityInput() {
    this.isEditingQuantityByInput = false;
  }

  private async onSelect(quantity: number) {
    if (this.isBusy) {
      return;
    }

    if (this.quantity === quantity) {
      this.hideQuantityInput();
      return;
    }

    try {
      this.isBusy = true;
      await this.onChange(quantity);
    } catch (error) {
      this.errors.log(error);
    } finally {
      this.hideQuantityInput();
      this.isBusy = false;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    QuantitySelect: typeof QuantitySelectComponent;
    'quantity-select': typeof QuantitySelectComponent;
  }
}
