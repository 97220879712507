import type { Store } from '@ember-data/store';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

import type EventModel from 'ticketbooth/models/event';
import type OrderModel from 'ticketbooth/models/order';
import { INCLUDE_EVENT_HUB } from 'ticketbooth/utils/order-api';

type URLArgs = {
  orderId?: string;
  eventId?: string;
};
export type EventHubRouteModel = {
  order: OrderModel;
  event: EventModel;
};

export default class EventHubRoute extends Route<EventHubRouteModel | null> {
  @service store!: Store;
  @service router!: RouterService;

  queryParams = {
    orderId: { refreshModel: true },
    eventId: { refreshModel: true }
  };

  async model({ orderId, eventId }: URLArgs) {
    if (!orderId || !eventId) return this.redirectToShows();

    const [order, event] = await Promise.all([
      this.store
        .findRecord('order', orderId, { include: INCLUDE_EVENT_HUB })
        .catch(() => this.redirectToShows()),
      this.store
        .findRecord('event', eventId, {
          include: 'venue,show.default-attachment'
        })
        .catch(() => this.redirectToShows())
    ]);

    if (!order || !event) return this.redirectToShows();
    if (!this.orderHasTicketLineItemForEvent(order, event)) {
      return this.redirectToShows();
    }

    return { order, event };
  }

  orderHasTicketLineItemForEvent(
    order: OrderModel,
    event: EventModel
  ): boolean {
    return !!order.effectiveTicketLineItems.find(tli => tli.event === event);
  }

  redirectToShows() {
    this.router.transitionTo('listings.shows');
    return null;
  }
}
