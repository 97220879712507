import Component from '@glimmer/component';

import type Store from '@ember-data/store';
import { hash } from '@ember/helper';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import type { LoadPaginatedResult } from 'tangram/components/async-pagination';

interface LoadPaginatedProducts {
  loadByTags: LoadPaginatedResult;
}

interface Signature {
  Args: {
    /**
     * Model 'product' contains all *regular* product types:
     *   - product
     *   - money-voucher
     *   - program-voucher
     *   - stream-product
     *   - package-product
     */
    modelName: 'product' | 'donation-product' | 'benefit-product';
    tags?: string | null;
    filter?: object;
  };
  Blocks: {
    default: [
      {
        actions: {
          loadByTags: ProductsProviderComponent['loadByTags'];
        };
      }
    ];
  };
}

export default class ProductsProviderComponent
  extends Component<Signature>
  implements LoadPaginatedProducts
{
  @service private store!: Store;

  @action
  async loadByTags(offset: number, limit: number) {
    const { tags, filter = {} } = this.args;

    const result = await this.store.query(this.args.modelName, {
      include: 'default-attachment',
      page: { offset, limit },
      ...(tags ? { filter: { tags, ...filter } } : { ...filter })
    });

    return {
      result: result.slice(),
      total: result.meta['records-count']
    };
  }

  <template>
    {{yield (hash actions=(hash loadByTags=this.loadByTags))}}
  </template>
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ProductsProvider: typeof ProductsProviderComponent;
    'products-provider': typeof ProductsProviderComponent;
  }
}
